import { Button, Container, Typography } from "@mui/material";
import React from "react";

export class OuterErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error: any, errorInfo: any) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <React.Fragment>
          <p>Извините, произошла техническая ошибка!</p>
          <button onClick={() => this.setState({ hasError: false })}>Neu laden</button>
        </React.Fragment>
      );
    }

    return this.props.children;
  }
}

export class InnerErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error: any, errorInfo: any) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container maxWidth="xl" sx={{ pt: 12, pb: 16 }}>
          <Typography component="h1" variant="h2" sx={{ pb: 3 }}>
            Произошла техническая ошибка
          </Typography>
          <Typography variant="body1" sx={{ pb: 5, maxWidth: "90ch" }}>
            Извините, этого не должно быть. Пожалуйста, сначала попробуйте перезагрузить страницу.
          </Typography>
        </Container>
      );
    }

    return this.props.children;
  }
}
