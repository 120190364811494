import { DefaultSeoProps } from "next-seo";

export const SEO_CONFIG: DefaultSeoProps = {
  defaultTitle: "Culinario Mortale – Игра про таинственное убийство",
  openGraph: {
    type: "website",
    locale: "ru",
    url: "https://www.culinario-mortale.ru/",
    site_name: "Culinario Mortale – Игра про таинственное убийство",
  },
  twitter: {
    handle: "@handle",
    site: "@site",
    cardType: "summary_large_image",
  },
  robotsProps: {
    notranslate: true,
  },
};
