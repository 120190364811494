import { CacheProvider, EmotionCache } from "@emotion/react";
import { CssBaseline, GlobalStyles, ThemeProvider } from "@mui/material";
import {
  FullConsentProvider,
  GoogleAnalytics,
  LocalizationProvider,
  SupportProvider,
} from "@cm-websites/common";
import { InnerErrorBoundary } from "./error-boundary";
import { OverlayProvider } from "@react-aria/overlays";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Theme } from "@mui/material";
import NiceModal from "@ebay/nice-modal-react";
import React from "react";

export const Providers: React.FC<{
  children: React.ReactNode;
  emotionCache: EmotionCache;
  theme: Theme;
  queryClient: QueryClient;
}> = ({ children, emotionCache, theme, queryClient }) => (
  <LocalizationProvider country="RU" detectLocale={false} market="RU" mode="catalogue">
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            ".react-modal-sheet-container": {
              "&:focus": {
                outline: "none",
                appearance: "none",
              },
            },
          }}
        />
        <InnerErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <OverlayProvider>
              <NiceModal.Provider>
                <FullConsentProvider>
                  <GoogleAnalytics
                    grantId="google-analytics"
                    trackingId={process.env.NEXT_PUBLIC_GA_TRACKING_ID ?? ""}
                  >
                    <SupportProvider enableQrScanner={false}>{children}</SupportProvider>
                  </GoogleAnalytics>
                </FullConsentProvider>
              </NiceModal.Provider>
            </OverlayProvider>
          </QueryClientProvider>
        </InnerErrorBoundary>
      </ThemeProvider>
    </CacheProvider>
  </LocalizationProvider>
);
